.onboarding {
  &_section {
    background-color: #100c23;
    display: flex;
    height: 100vh;
    overflow: hidden;
    width: 100%;
  }
  &_sidebar {
    background-color: #100c23;
    width: 35%;
    padding: 31px 20px 0px 81px;
    @media screen and (max-width: 1600px) {
      width: 32%;
    }
    .brand {
      &_title {
        margin-top: 10px;
        margin-left: 5px;
      }
    }
    .container {
      margin-top: 96px;
      @media screen and (max-width: 1600px) {
        margin-top: 58px;
      }
      .stepper {
        position: relative;
        align-items: flex-start;
        margin-bottom: 102px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 1600px) {
          margin-bottom: 95px;
        }
        &::after {
          content: '';
          height: 82px;
          box-sizing: border-box;
          background: #21143d;
          width: 3.5px;
          top: 63px;
          left: 26px;
          position: absolute;
          @media screen and (max-width: 1600px) {
            height: 72px;
            top: 57px;
            left: 22px;
          }
        }
        &:last-child {
          margin-bottom: 0px;
          &::after {
            content: unset;
          }
        }
        &.active {
          .step {
            background: linear-gradient(
              90deg,
              #40009a -23.26%,
              #a521f9 -23.24%,
              #7344fb 67.28%,
              #5a87fc 99.88%,
              rgba(217, 217, 217, 0) 150.58%,
              #6edbfd 150.58%
            );
            box-shadow: 0 0 70px #6868ff;
            .box {
              background: rgba(36, 32, 83);
              color: white;
            }
            &_title {
              color: #a092b5;
            }
          }
        }
        &.completed {
          .step {
            background: linear-gradient(
              90deg,
              #40009a -23.26%,
              #a521f9 -23.24%,
              #7344fb 67.28%,
              #5a87fc 99.88%,
              rgba(217, 217, 217, 0) 150.58%,
              #6edbfd 150.58%
            );
            .box {
              background: #100c23;
              color: white;
            }
            &_title {
              color: #a092b5;
            }
          }
          &::after {
            background: linear-gradient(
              60deg,
              #40009a -23.26%,
              #a521f9 -23.24%,
              #7344fb 67.28%,
              #5a87fc 99.88%,
              rgba(217, 217, 217, 0) 150.58%,
              #6edbfd 150.58%
            );
          }
        }
        .step {
          background: #21143d;
          position: relative;
          width: 53px;
          height: 53px;
          border-radius: 50%;
          padding: 3px;
          @media screen and (max-width: 1600px) {
            width: 46px;
            height: 46px;
          }
          .box {
            position: relative;
            background: #21143d;
            width: 47px;
            height: 47px;
            border-radius: 50%;
            font-size: 21px;
            font-weight: 500;
            color: #412f67;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 1600px) {
              font-size: 19px;
              width: 40px;
              height: 40px;
            }
            .step_count {
              @media screen and (max-width: 1600px) {
                margin-bottom: 3px;
              }
            }
          }
          &_title {
            font-size: 22px;
            font-weight: 500;
            font-style: normal;
            font-size: 22px;
            line-height: 26px;
            color: #412f67;
            margin-left: 14px;
            @media screen and (max-width: 1600px) {
              font-size: 19px;
            }
          }
        }
      }
    }
  }
  &_flow {
    background: linear-gradient(
        302.7deg,
        rgba(100, 1, 239, 0.2) 42.39%,
        rgba(0, 0, 0, 0) 157.66%
      ),
      #14171c;
    border-radius: 30px;
    width: 65%;
    margin: 30px 0;
    margin-right: 30px;
    @media screen and (max-width: 1600px) {
      width: 68%;
      margin: 17px 0;
      margin-right: 17px;
    }
    &_card_layout {
      min-width: 686px;
      max-width: 686px;
      margin-left: 240px;
      margin-top: 152px;
      color: #a092b5;
      @media screen and (max-width: 1600px) {
        margin-left: 192px;
        margin-top: 130px;
        min-width: 586px;
        max-width: 586px;
      }
      .step_progress {
        font-weight: 600;
        font-size: 24px;
        color: #a092b5;
        @media screen and (max-width: 1600px) {
          font-size: 20.5px;
        }
      }
      .flow_header {
        @apply text-white;
        letter-spacing: 0.006em;
        font-size: 39px;
        font-weight: 700;
        @media screen and (max-width: 1600px) {
          font-size: 33px;
        }
      }
      .flow_sub_header {
      }
      .info_tips {
        @apply text-lg;
        @media screen and (max-width: 1600px) {
          @apply text-sm;
        }
      }
    }
  }
}
