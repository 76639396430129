.notes-empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}
.contact-notes {
  padding: 30px 40px;
  height: calc(100% - 140px);
  max-height: calc(100% - 140px);
  overflow-y: auto;
  &__list {
    padding: 7px 26px 26px 11px;
    background: #fff8ec;
    border-radius: 10px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    .dots-action-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      background-color: transparent;
      border-radius: 4px;
      cursor: pointer;
      opacity: 0;
      visibility: hidden;
      &:hover {
        background-color: white;
      }
    }
    &:hover {
      .dots-action-btn {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
