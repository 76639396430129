.whatsapp-template-table-container {
  max-width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 5px;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  thead {
    // background-color: #f1f3f5;
    border-bottom: 1px solid #e0e0e0;
  }

  th,
  td {
    padding: 12px 15px;
    text-align: left;
  }

  th {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
  }

  td {
    font-size: 14px;
    font-weight: 400;
    color: #333;
  }

  tbody tr {
    border-bottom: 1px solid #e0e0e0;
  }

  tbody tr:last-child {
    border-bottom: none;
  }

  tbody tr:hover {
    background-color: #f9fafc;
  }

  .status.active {
    color: green;
    font-weight: 500;
  }

  .status.pending {
    color: orange;
    font-weight: 500;
  }
}
