.cs-container-divider {
  height: 1px;
  background-color: #e5e5e5;
  margin-top: 9px;
  margin-bottom: 10px;
}

.cs-container-label {
  color: #606566;
  font-weight: 600;
}

.create-patient {
  padding: 17px 26px 0px 26px;
  &--wrapper {
    @apply flex flex-col h-full justify-between;
  }
  &--patient-details {
    display: flex;
    gap: 30px;
  }
  &--btns {
    @apply flex gap-3 justify-end;
    padding: 17px 23px 17px 0px;
  }
  &--divider {
    height: 1px;
    background-color: #d8d8d8;
  }
  &--error-alert {
    @apply text-sm font-normal;
    padding: 8px 30px 11px 30px;
    &-create {
      color: #dd3040;
      background-color: #ffeff0;
    }
    &-link {
      @apply text-grey-900;
      background-color: #ffe5a7;
    }
  }
}
