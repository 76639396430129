.lds-ring {
  display: inline-block;
  position: relative;
  width: 45px;
  height: 45px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 44px;
  height: 44px;
  margin: 8px;
  border: 4px solid rgb(190, 34, 34);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #404791 transparent transparent transparent;
}

.lds-ring {
  &.small {
    width: 25px;
    height: 25px;
  }
  &.smaller {
    width: 20px;
    height: 20px;
    > div {
      width: 20px !important;
      height: 20px !important;
      border-width: 2px !important;
    }
  }
  &.tiny {
    width: 16px;
    height: 16px;
    > div {
      width: 16px !important;
      height: 16px !important;
      border-width: 1px !important;
    }
  }
}

.lds-ring.small div {
  width: 25px !important;
  height: 25px !important;
  border-width: 3px !important;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader_main {
  @apply flex;
  height: 100%;
  width: 100%;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}
.main {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #dee7e6;
  z-index: 999;
}
