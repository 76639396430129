.access-restricted-tooltip-main-container {
  position: absolute;
  display: inline-block;
  border-radius: 6px;
  border: 1px solid #dfdfdf;
  background-color: #fff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.25s ease-out;
}
.access-restricted-tooltip-after {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  background: #fff;
  left: 50%;
  bottom: -5px;
  transform: rotate(-45deg);
  border: 1px solid transparent;
  border-left-color: #dfdfdf;
  border-bottom-color: #dfdfdf;
  z-index: 999;
}
.access-restricted-tooltip-hover {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}
.access-restricted-tooltip {
  position: relative;
  z-index: 100;
  &__container {
    @extend .access-restricted-tooltip-main-container;
    &_left {
      @extend .access-restricted-tooltip-main-container;
      &::after {
        @extend .access-restricted-tooltip-after;
        left: 97%;
        bottom: 23px;
        transform: rotate(225deg);
      }
      &:hover {
        @extend .access-restricted-tooltip-hover;
      }
    }
    &::after {
      @extend .access-restricted-tooltip-after;
    }
    .btn--blue {
      @apply text-xs p-2 bg-[#404791] rounded-md;
    }
  }
  &__body {
    padding: 8px 20px;
    border-bottom: 1px solid rgba(#e6e4e1, 0.51);
  }
  &:hover .access-restricted-tooltip__container {
    @extend .access-restricted-tooltip-hover;
  }
}
