.chat-bot-controls {
  &-container {
    @apply border border-grey-400 bg-white;
    border-radius: 14px;
  }
  &__input-container {
    min-width: 85px;
    max-width: 85px;
    display: flex;
    align-items: center;
    padding: 6px 14px;
    background: #fff;
    border: 1.5px solid #c4c2c0;
    border-radius: 8px;
  }
  &__input {
    max-width: 25px;
    font-size: 15px;
    border: 0;
    &-with-border {
      max-width: 33px;
      font-size: 14px;
      text-align: center;
      padding: 2px 7px;
      background: #fff;
      border: 1.5px solid #c4c2c0;
      border-radius: 6px;
    }
  }
}

.select-default-dropdown {
  @apply flex items-center text-grey-900;
  font-size: 15px;
  padding: 6px 32px 6px 16px;
  background: #fff url(../../../assets/icons/icon-chevron-arrow-down.svg) right
    10px top 49% no-repeat;
  border: 1.5px solid #c4c2c0;
  border-radius: 8px;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

.bot-preview {
  $white: #ffffff;
  $medium: 500;
  --primary-text-color: #000000;
  --primary-bg-color: #ff00ff;
  --pop-over-bg: #ffffff;
  --option-container: #ffffff;
  --option-background: #ffffff;
  --option-text: #000000;

  .chat-container {
    // max-width: 60%;
    .chat-bubble {
      display: inline-block;
      padding: 0.6rem 1rem;
      border-radius: 18px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 18px;
      background-color: $white;
      box-shadow: 0 1px 10px 0 rgba(59, 68, 164, 0.31);
      &:not(:first-of-type) {
        margin-top: 10px;
      }
      &__cont {
        color: var(--primary-text-color);
        font-weight: $medium;
        letter-spacing: 0.2px;
        line-height: 1.9rem;
      }
      &-container {
        width: calc(100% - 56px);
        margin-left: 0.8rem;
      }
    }
  }

  .bot-eb-trigger-container {
    // position: absolute;
    // bottom: 10px;
    // right: 1rem;
    margin-top: 60px;
    display: flex;
    justify-content: end;
    max-width: 300px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .bot-eb-trigger {
    position: relative;
    cursor: pointer;
    background: var(--primary-bg-color);
    outline: none;
    height: 60px;
    width: 217px;
    opacity: 1;
    border-radius: 40px;
    transition: all 0.3s ease;
    // border: 2px solid transparent;
    vertical-align: middle;

    .bot-e-text {
      position: absolute;
      top: 3px;
      left: 24px;
      width: 135px;
      opacity: 1;
      display: flex;
      align-items: center;
      height: 55px;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      .bot-text {
        color: white;
        font-family: proxima-nova, sans-serif !important;
        font-size: 16px;
        font-weight: 500 !important;
        font-style: unset !important;
        // text-transform: capitalize !important;
        text-decoration-style: unset !important;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
        line-height: 1.4 !important;
      }
    }
  }

  .count {
    display: inline-block;
    height: 20px;
    width: 20px;
    background: rgb(235, 56, 94);
    font-size: 10px;
    border-radius: 50%;
    color: white;
    position: absolute;
    top: 1px;
    right: 1px;
    vertical-align: middle;
    line-height: 20px;
    z-index: 3;
    text-align: center;
  }
  .round-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    // background: rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    padding: 14px;
    position: absolute;
    top: 6px;
    right: 3px;

    .material-icons {
      color: white;
      font-size: 38px;
    }
    .bot-img {
      height: 50px;
      width: 50px;
      // top: 0px;
      max-width: unset !important;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .powered {
    position: absolute;
    background: #eceff1;
    padding: 4px 9px;
    border-radius: 20px;
    min-width: 120px;
    width: -moz-max-content;
    width: -webkit-max-content;
    width: -o-max-content;
    width: -ms-max-content;
    color: var(--pop-over-bg);
    font-size: 12px;
    text-decoration: none;
    font-family: proxima-nova, sans-serif !important;
    font-weight: 600 !important;
    font-style: unset !important;
    text-transform: none !important;
    text-decoration-style: unset !important;
    text-decoration: none !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    line-height: 1.4 !important;
    transition: all 0.8s ease;
    right: 65px;
    top: 19px;
    z-index: 9;

    .power {
      color: var(--pop-over-bg);
      font-size: 12px;
      font-family: proxima-nova, sans-serif !important;
      display: inline;
      font-style: unset !important;
      text-transform: none !important;
      text-decoration-style: unset !important;
      -webkit-font-smoothing: antialiased !important;
      -moz-osx-font-smoothing: grayscale !important;
      text-decoration: none !important;
    }
    .power::before {
      content: '\26A1';
      margin-left: -1px;
      margin-right: 1px;
      font-size: 10px;
    }
  }
  // bot image
  .bot-avatar-wrap {
    max-width: 40px;
    max-height: 40px;
    img {
      width: 43px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
    .user-info {
      // @include avatar(40px, 40px, #78858d, 50%);
      min-width: 40px;
      min-height: 40px;
      max-height: 40px;
      max-width: 40px;
      text-align: center;
      font-size: 1.6rem;
      color: #ffffff;
      line-height: 19px;
      font-weight: 600;
      > img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .bot-avatar-transparent {
      background-color: transparent !important;
    }
  }

  .mcq-container {
    background: var(--option-container);
    box-shadow: 0 0px 2px 0 rgba(59, 68, 164, 0.3);
    border-radius: 10px;
    padding: 10px;
    max-width: 300px;
    margin: 10px auto;
    box-sizing: border-box;
    position: relative;
    right: 1px;
    width: 85%;
    // animation: errorSlideUp 0.3s ease 1;
  }

  .options.grid .label {
    position: absolute;
    height: 100%;
    width: 100%;
    /* padding: 20px; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .options .label {
    display: block;
  }
  .options {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .options.nowrap {
    flex-wrap: nowrap;
  }
  .options.grid {
    flex-direction: row;
  }
  .options.options.grid .mcq-item {
    flex-basis: 26%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding-left: 20px;
    text-align: center;
    min-height: 45px;
    margin: 5px;
    -webkit-tap-highlight-color: transparent;
  }

  .mcq-item {
    background: var(--option-background);
    /* border: 1px solid #dadde0; */
    box-shadow: 0 2px 7px 0 rgba(177, 164, 164, 0.5);
    border-radius: 5px;
    padding: 15px 20px;
    padding-left: 17px;
    margin: 7px 2px;
    font-weight: 500;
    color: var(--option-text);
    position: relative;
    box-sizing: inherit;
    flex-basis: 100%;
    cursor: pointer !important;
  }
  .mcq-item input {
    // position: absolute;
    // opacity: 0;
    // height: 0;
    // width: 0;
  }

  .mcq-item input ~ .label-text:before {
    // content: "\E836";
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    position: absolute;
    left: 10px;
    top: 12px;
  }
}
