.org {
  &_header {
    border: 1px solid rgb(230 228 225 / var(--tw-border-opacity));
    display: flex;
    width: 100%;
    align-items: center;
    box-shadow: 0px 3.997px 5.996px -2.998px rgba(85, 83, 80, 0.2),
      0px -0.999px 0px 0px rgba(229, 229, 229, 0.8);
    height: 63px;
    padding-left: 25px;
    &__title {
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      @apply text-grey-900;
    }
  }
}
