.indicatorContainer {
  .updateStyles {
    &__control {
      height: 48px;
      .updateStyles__value-container {
        .updateStyles__placeholder {
          color: #9ca4af;
          font-weight: 500;
        }
        .updateStyles__single-value {
          font-weight: 500;
          color: #292929;
        }
      }
      .updateStyles__indicators {
        .updateStyles__clear-indicator {
          display: none;
        }
        .updateStyles__indicator-separator {
          display: none;
        }
        .updateStyles__indicator {
          padding-right: 14px;
          svg {
            width: 17px;
            height: 17px;
            path {
              color: rgb(76, 82, 84);
            }
          }
        }
      }
    }
  }
}
