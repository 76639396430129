@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'styles/styles.scss';
body {
  @apply text-grey-900;
  font-size: 16px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.0024em;
  background: linear-gradient(
      303deg,
      rgba(100, 1, 239, 0.2) 42.39%,
      rgba(0, 0, 0, 0) 157.66%
    ),
    #14171c;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input,
button,
code {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  &::placeholder {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
      Roboto, 'Helvetica Neue', Arial, sans-serif;
  }
}
input,
input:focus-visible,
textarea,
textarea:focus-visible {
  outline: none;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c3c2c0;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

img {
  display: inline;
  vertical-align: baseline;
}

// css for fixing 90% zoom level for macbooks
html {
  height: 100%;
  @media screen and (max-width: 1500px) {
    height: 100%;
  }
}

body {
  height: 100%;
  @media screen and (max-width: 1500px) {
    height: 100%;
  }
}

div[id='root'] {
  height: 100%;
  @media screen and (max-width: 1500px) {
    height: 100%;
  }
}

.dashboard {
  height: 100%;

  @media screen and (max-width: 1500px) {
    height: 100%;
    zoom: 0.9;
    @supports (-moz-appearance: none) {
      zoom: 1;
    }
  }
}

.pre {
  white-space: pre-wrap !important;
}

.overflow_y {
  overflow-y: scroll;
}

.rhap_container {
  background-color: rgba($color: #eef7fc, $alpha: 0.01) !important;
  box-shadow: none !important;
  padding: 0px !important;
}

.rhap_controls-section {
  flex: initial !important;
}

.rhap_main {
  flex-direction: row-reverse !important;
}

.rhap_stacked .rhap_controls-section {
  margin-top: 0px !important;
}

.bg_dot {
  background-image: url('../src/assets/icons/boxly/dot_bg.png');
}
// .react-resizable {
//   position: relative;
// }
// .react-resizable-handle {
//   position: absolute;
//   width: 20px;
//   height: 20px;
//   bottom: 0;
//   right: 0;
//   background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
//   background-position: bottom right;
//   padding: 0 3px 3px 0;
//   background-repeat: no-repeat;
//   background-origin: content-box;
//   box-sizing: border-box;
//   cursor: se-resize;
// }
