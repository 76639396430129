.tooltip {
  @apply bg-secondary;
  margin: 50px auto;
  width: 447px;
  height: 158px;
  box-shadow: 0px 10px 12px rgba(85, 83, 80, 0.12);

  &__close-icon {
    @apply absolute;
    width: 9px;
    height: 9px;
    right: 15px;
    top: 17px;
  }

  &::after {
    @apply absolute;
    content: '';
    bottom: 100%;
    left: 0px;
    margin: 0px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #0491ab #0491ab;
  }
  &__hand-icon {
    @apply absolute;
    width: 56px;
    height: 56px;
    right: 12px;
    bottom: 10px;
  }
}
.enquiry-assistant {
  &__card {
    @apply flex flex-col items-center justify-center mr-1 cursor-pointer;
    padding: 14px;

    &:hover {
      @apply bg-grey-55 rounded-lg;
      transition: all 300ms ease-in;

      .enquiry-assistant__card--avatar {
        @apply bg-white;
      }
    }

    &--avatar {
      @apply relative flex flex-shrink-0 items-center justify-center bg-grey-55;
      border-radius: 9999px;
    }
  }
}
.toggle-slider {
  &__checkbox {
    width: initial;
    position: absolute;
    opacity: 0;
    &:checked + label .toggle-slider__ball {
      background: #404791 !important;
      &:before {
        left: 24px;
      }
      &__larger {
        &:before {
          left: 20px;
        }
      }
      &__large {
        &:before {
          left: 25px;
        }
      }
      &__small {
        &:before {
          left: 17px;
        }
      }
      &__smaller {
        &:before {
          top: 2px !important;
          left: 14px !important;
        }
      }
    }
    &:checked + label .toggle-slider__txt {
      color: rgb(182, 181, 181);
    }
  }
  &__ball {
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 20px;
    background: #c4c2c0 !important;
    border-radius: 22px;
    cursor: pointer;
    padding: 0;
    margin: 0 0 0 1rem;
    position: relative;
    transition: all 0.4s ease;
    &__larger {
      width: 39px;
      height: 22px;
      &:before {
        content: '';
        width: 17px !important;
        height: 17px !important;
      }
    }
    &__large {
      width: 42px;
      height: 18px;
      &:before {
        content: '';
        width: 14px !important;
        height: 14px !important;
      }
    }
    &__small {
      width: 31px;
      height: 16px;
      &:before {
        content: '';
        width: 12px !important;
        height: 12px !important;
      }
    }
    &__smaller {
      width: 25px;
      height: 13px;
      &:before {
        content: '';
        width: 9px !important;
        height: 9px !important;
        top: 2px !important;
        left: 2px !important;
      }
    }
    &:before {
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: white;
      box-shadow: inset 0 1px 4px 0 rgba(221, 214, 214, 0.5),
        0 0 2px 0 rgba(36, 16, 16, 0.4);
      position: absolute;
      top: 2px;
      left: 3px;
      transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }
}
.toggle-slider__checkbox:checked + label .toggle-slider__ball {
  background: #404791 !important;
}

.toggle-slider__checkbox:checked + label .primary {
  background: #6759d6 !important;
}

.toggle-slider__checkbox:checked + label .dark-blue {
  background: #404791 !important;
}
