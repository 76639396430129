.tabs {
  &-wrapper {
    @apply w-full flex items-center border-b border-grey-400;
    &__borderless {
      border: none !important;
      .tabs-header {
        font-size: 16px;
        color: #7d8081 !important;
        padding-bottom: 19px;
        &.active {
          color: #2b3639 !important;
          font-weight: 500;
        }
        &::after {
          background-color: #2b3639 !important;
          height: 1.5px;
        }
      }
    }
    &--contact {
      padding: 20px 40px 0;
      .tabs {
        &-header {
        }
      }
    }
    &--labels {
      height: 40px;
      position: absolute;
      top: 19px;
      border-width: 0px;
    }
  }
  &-header {
    font-size: 13px;
    @apply relative flex items-center font-medium text-grey-700 pb-3 cursor-pointer;

    &__channel {
      @apply text-grey-900;
      font-size: 16px;
      padding-bottom: 19px;
      padding-top: 7px;
    }
    &::after {
      content: '';
      @apply w-full absolute left-0 bottom-0 bg-primary opacity-0 invisible;
      height: 2px;
      transition: all 300ms;
    }
    &__active {
      @apply font-semibold text-primary;
      &::after {
        @apply opacity-100 visible;
      }
    }
    &__active-secondary {
      @apply font-semibold text-primary;
      &::after {
        @apply opacity-100 visible bg-blue-dark;
      }
    }
    &.active {
      @apply font-semibold text-primary;
      &::after {
        @apply opacity-100 visible;
      }
    }
    &:not(:last-child) {
      margin-right: 25px;
    }
  }
}
