.chat-reply {
  // padding: 0px 10px;
  height: 100%;
  .section-top {
    padding: 0px 10px;
  }
  &.error-editor {
    .ql-editor.ql-blank::before {
      color: #ff0000 !important;
      // content: 'Please add message';
    }
    .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
      color: #ff0000;
    }
    .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
      color: #ff0000 !important;
      content: 'Please add message' !important;
    }
    .tox-edit-area__iframe.mce-content-body {
      &::before {
        color: #ff0000 !important;
        content: 'Please add message';
      }
    }
  }
  .quill {
    padding: 0px 10px;
  }

  .info-block {
    padding: 10px 0px;
    font-size: 13px;
    display: flex;
    justify-content: start;
    border-bottom: 1px solid #e6e4e1;

    &.space {
      justify-content: space-between;
    }
    .label {
      @apply text-grey-600;
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
    }
    .from-email {
      @apply flex items-center justify-center;
      font-weight: 400;
      font-size: 13px;
    }
    .cc {
      font-size: 13px;
    }
    .email-text {
      color: #000000;
      font-size: 14px;
      line-height: 19px;
      padding: 0;
      border: 0;
      min-width: 120px;
      background: transparent;
      width: 100%;
    }

    .email-pill-wrapper {
      @apply flex items-center;
      flex-wrap: wrap;
    }
    .email-pill {
      @apply flex items-center justify-center;
      color: #393f53;
      font-size: 13px;
      line-height: 19px;
      border: 1px solid #e3e3e3;
      border-radius: 3px;
      background-color: #f4f4f4;
      padding: 0 5px;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    .add-more {
      color: rgba(57, 63, 83, 0.5);
      font-size: 13px;
      line-height: 19px;
      padding: 0;
      border: 0;
      background: transparent;
      margin-left: 3px;
      cursor: pointer;
    }
  }

  .ql-container.ql-snow {
    border-width: 0px !important;
  }

  .ql-editor {
    padding: 10px 3px;
  }
  // .text-editor {
  //   .quill {
  //     .ql-editor {
  //       padding: 10px 3px;
  //       min-height: 57px;
  //       max-height: unset;
  //     }
  //   }
  // }

  .error-editor {
    .ql-editor.ql-blank::before {
      color: #ff0000 !important;
      // content: "Please add message";
    }
  }

  .ql-editor.ql-blank::before {
    left: 3px !important;
    font-style: normal;
  }

  .attachments-wrapper {
    .attachments-title {
      @apply text-grey-900;
      margin-left: 15px;
      font-size: 12px;
      margin-bottom: 5px;
    }
    .attachments-container {
      @apply flex flex-wrap bg-grey-300;
      padding: 0.75rem 0 0 0;
    }
    .attachments {
      @apply flex items-center px-3 py-2 bg-white;
      font-size: 12px;
      border: 1px solid #e6e4e1;
      border-radius: 8px;
      max-width: 267px;
      min-width: 267px;
      margin: 0 0.6rem 0.9rem 0.6rem;
      .label-container {
        @apply flex justify-between items-center;
        max-width: 218px;
        min-width: 218px;
        word-break: break-word;
        .trash {
          height: 15px;
          width: 15px;
        }
      }
      .file-icon {
        width: 50px;
        height: 50px;
      }
      .label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 5px;
        margin-right: 5px;
        font-weight: 500;
      }
    }
  }
}

.tox .tox-edit-area::before {
  border-width: 0px !important;
}

.text-editor {
  .signature {
    position: relative;
    &-close {
      position: absolute;
      top: 0;
      right: 10px;
    }
  }
}

.ql-toolbar-button {
  display: flex !important;
  padding: 0px !important;
  height: initial !important;
  width: initial !important;
}

.enquiry-email-editor,
.enquiry-sms-editor {
  .ql-container {
    .ql-editor {
      // min-height: 57px !important;
    }
  }
}

.enquiry-email-editor-full {
  // min-height: calc(100%) !important;
  .ql-container {
    .ql-editor {
      // min-height: 57px !important;
      // min-height: 113px !important;
    }
  }
}

.enquiry-fb-editor {
  .ql-container {
    .ql-editor {
      min-height: 71px !important;
    }
  }
}

.reply-template-editor {
  .ql-container {
    .ql-editor {
      min-height: 300px !important;
      max-height: 300px !important;
    }
  }
}

.signature-custom-editor {
  .ql-container {
    border-radius: 14px !important;
    .ql-editor {
      min-height: 300px !important;
      max-height: 300px !important;
    }
  }
}

.box-automation-email {
  .ql-container {
    .ql-editor {
      min-height: 180px !important;
    }
  }
}

.box-automation-sms {
  .ql-container {
    .ql-editor {
      min-height: 120px !important;
      max-height: 120px !important;
    }
  }
}

.channel-automation-sms {
  .ql-container {
    .ql-editor {
      min-height: 180px !important;
      // max-height: 180px !important;
    }
  }
}

.channel-automation-facebook {
  .ql-container {
    .ql-editor {
      min-height: 240px !important;
      // max-height: 240px !important;
    }
  }
}

.channel-automation-email {
  .ql-container {
    .ql-editor {
      min-height: 180px !important;
    }
  }
}

.whatsapp-reply {
  .ql-container {
    .ql-editor {
      min-height: 128px !important;
    }
  }
}

.email-web-developer {
  .ql-container {
    .ql-editor {
      font-size: 15px;
    }
  }
}
