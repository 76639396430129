.contacts-tab {
  @apply h-full py-8 pt-4 overflow-y-auto px-10;
  .question-ans-list {
    @apply bg-white border border-grey-400 mt-5;
    border-radius: 10px;
    transition: all 300ms;
    &:hover,
    &.active {
      @apply border border-secondary;
      box-shadow: 0px 6px 14px -2px rgba(85, 83, 80, 0.15);
    }
    &__title {
      font-size: 15px;
      @apply font-medium;
      &-wrapper {
        @apply flex items-center justify-between;
        padding: 15px 15px 12px 20px;
      }
    }
    &__body {
      @apply bg-grey-300;
      padding: 15px 15px 12px 20px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}
