.cs-phone-numbers {
  color: #454f51;
  thead {
    tr {
      @apply border-b;
      border-color: #f2f1f0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      text-align: left;
      background-color: #f9f9f9;
      th {
        @apply text-sm font-bold text-base;
        padding: 10px 15px;
        &:first-child {
          border-top-left-radius: 8px;
        }
        &:last-child {
          border-top-right-radius: 8px;
          text-align: right;
        }
      }
    }
  }
  tbody {
    tr {
      @apply border-b;
      border-color: #f2f1f0;
      td {
        @apply text-sm font-medium;
        padding: 15px 15px;
        &:first-child {
          @apply font-medium;
          font-size: 15px;
        }
        &:nth-child(2) {
          width: 200px;
          text-align: center;
        }
      }
      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border: none;
      }
    }
  }
  &-wrapper {
    @apply border rounded-lg;
    border-color: #f2f1f0;
  }
}

.cs-phones-call-insights {
  &--header {
    @apply flex items-center gap-10;
    img {
      @apply border-grey-400 rounded-full;
      height: 32px;
      width: 32px;
      border-width: 1px;
    }
  }
  &--agent {
    div {
      &:first-child {
        @apply text-base font-medium text-grey-800;
      }
      &:last-child {
        @apply text-sm font-normal;
        color: #939393;
      }
    }
  }
  &--table {
    tr {
      td {
        @apply font-medium;
        font-size: 15px;
        padding-bottom: 20px;
        &:first-child {
          @apply text-grey-700;
          padding-right: 50px;
        }
        &:last-child {
          @apply text-grey-900;
        }
      }
    }
  }
}
