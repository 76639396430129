.custom-checkbox {
  width: 20px;
  height: 20px;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 4px;
  @apply relative bg-white bg-transparent cursor-pointer border-2 border-primary;
  transition: all 300ms ease-out;
  &::after {
    content: '';
    width: 14px;
    height: 10px;
    @apply absolute opacity-0 invisible;
    background: url(/assets/icons/icon-checkbox-circle.svg) no-repeat center;
    top: 4px;
    right: 1px;
  }
  &:checked {
    @apply bg-primary;
    border: 2px solid transparent;
    &::after {
      @apply opacity-100 visible;
    }
  }
  &--sm {
    width: 18px;
    height: 18px;
    &::after {
      top: 3px;
      right: 0;
    }
  }
  &--circle {
    border-radius: 50%;
    border: 1.5px solid #908f8d;
  }
  &--boxly {
    border: 2px solid #635df5;
    &:checked {
      background: #635df5;
    }
  }
  &--blue-dark {
    border: 2px solid #404791;
    &:checked {
      background: #404791;
    }
  }
}
