.table {
  display: flex;
  flex-direction: column;
  border: 1px solid #eaecf0;
  border-radius: 10px;
}

.row {
  display: flex;
  width: 100%;
  align-items: center;
  &--header {
    @apply font-medium;
    background: #f9f9f9;
    color: #595d65;
    border: 0px;
    height: 50px;
    padding: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &--sub-row {
    @apply font-medium;
    padding: 14px 10px;
    border-top: 1px solid #eaecf0;
    color: #101828;
  }
}

.col-60 {
  width: 70%;
  padding-left: 20px;
  padding-right: 30px;
}

.col-80 {
  width: 80%;
  padding-left: 20px;
  padding-right: 30px;
}

.col-20 {
  display: flex;
  align-items: center;
  width: 20%;
  &--options {
    @apply justify-end pr-5;
  }
}

.col-40 {
  display: flex;
  align-items: center;
  width: 30%;
}

.col-70 {
  width: 70%;
}

.col-30 {
  width: 30%;
  display: flex;
  align-items: center;
  &--options {
    @apply justify-end pr-5;
  }
}
