.box-card-wrapper {
  @apply flex w-full h-full overflow-x-auto overflow-y-hidden;

  &::-webkit-scrollbar {
    display: block;
    height: 7px;
  }
  &.is-filter-enabled {
  }
  &.is-mouse-user {
    &::-webkit-scrollbar {
      display: block;
      height: 12px;
    }
  }

  &.box-detail {
    overflow: hidden;
  }
}

.box-container {
  height: calc(100% - 65px);

  &.with-filter {
    height: calc(100% - 117px);
  }
}

.box-scroller-container {
  height: calc(100% - 69px);
  &.general {
    height: calc(100% - 47px);
  }
  .droppable-empty-container {
    border: 3px solid rgba(230, 228, 225, 0.2);
    border-color: rgb(230 228 225 / var(--tw-border-opacity));
    border-style: dashed;
    border-radius: 5px;
    height: 150px;
    padding: 0 13px;
    margin-top: 5px;
    width: 100%;
  }
}

.box-card-container {
  @apply bg-grey-300 pt-3 pb-2 rounded-lg relative;
  border: 1px solid transparent;
  max-height: calc(100% - 10px);
  width: 100%;
  &.active {
    border: 1px solid #039855;
    background: rgba(3, 152, 85, 0.07);
    .clip-path {
      right: -2px;
      &--border {
        @apply block;
        top: -1px;
        right: -1px;
      }
    }
    .stage-value {
      @apply text-white;
      background: #039855;
    }
  }

  &.fix-width {
    min-width: 420px;
    max-width: 420px;
  }

  .conversion-stage-option {
    @apply bg-grey-300 font-semibold;
    border-top: 1px solid #e6e4e1;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 0.875rem;
    padding: 10px 13px 13px 13px;
  }

  &__add {
    @apply bg-grey-300 pt-4 px-2 pb-6 rounded-lg;
    min-width: 404px;
    max-width: 404px;
    min-height: fit-content;
  }
  .box-scroller {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    &::-webkit-scrollbar-thumb {
      display: none;
    }
    &:hover::-webkit-scrollbar-thumb {
      display: block;
    }
  }
  .replay-later-scroller {
    overflow: auto;
    min-width: 404px;
    max-width: 404px;
  }
  &:not(:last-child) {
    @apply mr-5;
  }
  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      min-height: 100%;
      overflow: hidden !important;
    }
  }
}

.stage-option {
  @apply flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 27px;
  border-radius: 6px;
  &.active {
    @apply bg-grey-30;
  }
}

.box-card {
  @apply flex bg-white cursor-pointer;
  width: 100%;
  border-radius: 10px;
  padding: 12px 12px 13px;
  border: 1px solid #e6e4e1;
  box-shadow: 0px 2px 5px -1px rgba(85, 83, 80, 0.08);
  &--empty {
    border: 1px solid transparent;
    box-shadow: none;
    cursor: default;
  }
  &:not(:first-child) {
    margin-top: 0.75rem;
  }

  &.active {
    @apply border-grey-400 bg-grey-55;
    .sub-border {
      @apply border-[#D6D7FF] border-b;
    }
  }

  .notification-label {
    @apply flex items-baseline gap-1;
    padding: 2px 8px;
    border-radius: 20px;

    &__reply-recieved {
      @apply text-xs text-white font-semibold bg-secondary;
    }
  }

  &__time {
    @apply text-grey-700 text-sm text-right whitespace-nowrap;
  }

  &__title {
    @apply text-grey-800 font-medium text-base truncate;
  }

  &__msg {
    font-size: 15px;
    width: 85%;
    @apply text-grey-800 font-medium truncate;

    &__isdummy {
      font-size: 15px;
      width: 85%;
      @apply text-grey-800 font-medium;
    }
  }

  &__question {
    font-size: 15px;
    width: 90%;
    @apply text-grey-800 font-normal truncate;
  }

  &--new {
    .notification-label {
      font-size: 10px;
      @apply font-semibold text-white bg-green-700 uppercase;
    }

    .notification-status {
      @apply text-xs font-semibold text-white;
      background: #0491ab;
    }
    .box-card {
      &__time {
        @apply text-grey-900;
      }
      &__title {
        @apply text-grey-900 font-bold;
      }
      &__msg {
        @apply text-grey-900 font-bold;
      }
    }
  }
}

.box-filter-assignTo {
  @apply bg-white border-radius-20 flex items-center;
  padding: 3px 10px 3px 3px;

  &:not(:last-child) {
    margin-right: 2px;
  }
}

.create-box-card-container {
  cursor: pointer;
  position: relative;
  width: 300px;
  height: 233px;
  background: #fff;
  box-shadow: 0px 2.5px 7.5px -2.5px rgba(0, 0, 0, 0.15);
  border: 1px solid #f0f3f8;
  border-radius: 10px;

  &:hover {
    box-shadow: none;
    border: 1px solid transparent;
    border-radius: 20px;
  }

  &:hover::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 20px;
    padding: 1px;
    background: linear-gradient(
        250deg,
        rgba(255, 230, 148, 1),
        rgba(100, 1, 239, 1),
        rgba(60, 75, 166, 1)
      )
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}
