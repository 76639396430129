.toggle-container {
  @apply table-cell align-middle text-center;

  label {
    @apply text-sm font-medium;
  }
  &__switch {
    @apply inline-block m-0 relative;
  }
}
.toggle-container__switch > .toggle-container__switch-inner {
  @apply bg-grey-300 overflow-hidden relative m-0;
  width: 154px;
  height: 32px;
  border: 2px solid rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  transition: all 0.3s ease;
}

.toggle-container__switch input[type='checkbox'] {
  @apply cursor-pointer opacity-0 absolute m-0;
  width: 15px;
  height: 15px;
  top: 8px;
  z-index: 1;
}

.toggle-container__switch > .toggle-container__switch-inner:before {
  content: '';
  @apply absolute font-medium text-grey-800 text-sm cursor-pointer;
  top: 4px;
  right: 19px;
}

.toggle-container__switch > .toggle-container__switch-inner:after {
  content: '';
  @apply absolute bg-primary text-center cursor-pointer;
  width: 74px;
  height: 26px;
  border: 2px solid #055768;
  box-shadow: 0px -1px 4px -2px rgba(0, 0, 0, 0.5), 0px 2px 4px -2px rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  left: 0px;
  top: 1px;
  transition: all 0.3s ease;
  padding: 1px 0px;
}

.toggle-container__switch input[type='checkbox']:checked + .toggle-container__switch-inner {
  @apply bg-grey-300;
}
.toggle-container__switch input[type='checkbox']:checked + .toggle-container__switch-inner:after {
  content: '';
  @apply bg-primary;
  left: 74px;
  padding-left: 10px;
}
.toggle-container__switch
  input[type='checkbox']:checked
  + .toggle-container__switch-inner
  .mine-text {
  @apply text-white;
}
.toggle-container__switch
  input[type='checkbox']:checked
  + .toggle-container__switch-inner
  .all-text {
  @apply text-grey-800;
}

.tooltiptext {
  position: absolute;
  background-color: #2b3639;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  z-index: 1;
  padding: 5px 10px;

  &__toggle {
    bottom: 37px;
    margin-left: 0;
    right: -45px;
  }
  &__all-tooltip {
    bottom: 37px;
    left: -12px;
  }
}
.tooltiptext::after {
  content: '';
  position: absolute;
  border-width: 5px;
  border-style: solid;
}
.tooltiptext__toggle::after {
  top: 116%;
  right: 50%;
  margin-top: -5px;
  border-color: #2b3639 transparent transparent transparent;
}
.tooltiptext__all-tooltip::after {
  top: 116%;
  right: 50%;
  margin-top: -5px;
  border-color: #2b3639 transparent transparent transparent;
}
.custom-tooltip:hover .tooltiptext {
  visibility: visible;
}

.toggle-switch {
  @apply relative;
  &__label {
    @apply relative flex items-center justify-center bg-white cursor-pointer;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    &::after {
      content: '';
      @apply absolute bg-primary;
      width: 48%;
      height: 90%;
      top: 5%;
      left: 2%;
      border-radius: 20px;
      transition: all 300ms;
    }
  }
  &__checkbox {
    @apply opacity-0 hidden;
    &:checked {
      & + label {
        &::after {
          left: 50%;
        }
        .toggle-switch__item {
          &:first-of-type {
            @apply text-primary;
          }
          &:last-of-type {
            @apply text-white font-semibold;
          }
        }
      }
    }
  }
  &__item {
    @apply flex items-center justify-center w-6/12 font-medium;
    font-size: 13px;
    padding: 5px 20px;
    z-index: 1;
    transition: color 300ms 100ms;
    &:first-of-type {
      @apply text-white font-semibold;
    }
    &:last-of-type {
      @apply text-primary;
    }
  }
}
