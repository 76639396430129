.chats {
  &-preview {
    @apply flex items-end cursor-pointer;
    padding: 10px 0;

    &__avatar-container {
      width: 48px;
      height: 48px;
      @apply flex-shrink-0 rounded-full overflow-hidden;
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);

      > img {
        @apply w-full h-full rounded-full object-cover;
      }
      &--contain {
        > img {
          @apply object-contain;
          width: initial;
          height: initial;
          border-radius: 0;
        }
      }
    }

    &.active {
      .sub-border {
        @apply border-[#D6D7FF] border-b;
      }
    }
    .on-hover-border-color {
      @apply border-b-[1px] border-[#FAFAFA] border-solid;
    }
    .border-color-restored {
      @apply border-b-[1px] border-[#f1d5d5] border-solid;
    }
    &:hover {
      .on-hover-border-color {
        border-color: #d6d7ff !important;
        transition: all 300ms ease-in !important;
      }
    }
    &__title {
      @apply text-grey-800 font-medium truncate;
    }

    &__msg {
      font-size: 15px;
      @apply text-grey-800 font-medium truncate;
    }

    &__default-task {
      @apply pt-[6px] pb-[10px] mb-[10px] block;
      &:hover,
      &.active {
        .default-task-top {
          @apply border-[#D6D7FF] border-b;
        }
      }
    }

    .notification-label {
      @apply flex items-center gap-1;
      padding: 2px 8px;
      border-radius: 20px;

      &__reply-recieved {
        @apply text-xs text-white font-semibold bg-secondary;
      }
    }

    &__add-info {
      @apply flex flex-col flex-shrink-0 gap-2 ml-auto;
    }

    &__time {
      @apply text-grey-700 text-sm text-right whitespace-nowrap;
    }

    &--new {
      .chats {
        &-preview {
          &__title {
            @apply text-grey-900 font-bold;
          }

          &__msg {
            @apply text-grey-900 font-bold;
          }

          &__time {
            @apply text-grey-900;
          }
        }
      }
      &:not(:first-child) {
        margin-top: 6px;
      }
      .notification-label {
        font-size: 11px;
        @apply font-bold text-white bg-green-700 uppercase;
      }

      .notification-status {
        @apply text-xs font-semibold text-white;
        background: #0491ab;
      }
    }

    &--reply-received {
      .chats {
        &-preview {
          &__title {
            @apply text-grey-900 font-semibold;
          }

          &__msg {
            @apply text-grey-800;
          }

          &__time {
            @apply text-grey-900;
          }
        }
      }

      .notification-label {
        @apply text-xs text-white font-semibold bg-secondary;
      }
    }
  }
}

.default-task-top {
  @apply border-b border-grey-300-secondary;
}
